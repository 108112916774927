import { useMemo } from "react";

import moment from "moment";
import { useSelector } from "react-redux";
import { selectBalanceList, selectThirdPartyBankAccountList, selectTradeList } from "redux/slices/selectors";
import { CANADA_PROVINCE_CODE_TO_NAME } from "helpers/regions";
import { CANADA_PROVINCE_TO_THIRDPARTY } from "helpers/countries";

const usePaymentModal = ({ task = {} }) => {
    const { tradeId, paymentId } = task;
    const thirdPartyBankAccounts = useSelector(selectThirdPartyBankAccountList);
    const balanceList = useSelector(selectBalanceList);
    const tradeList = useSelector(selectTradeList);
    const today = moment().endOf('day').format("YYYY-MM-DD");

    const trade = useMemo(() => tradeList?.find((tradeItem) => tradeItem.id === tradeId) ?? {}, [tradeId, tradeList]);
    const currencyCode = useMemo(() => trade?.sellCurrencyCode, [trade?.sellCurrencyCode]); // USD
    const thirdPartyCurrencyCode = useMemo(() => trade?.buyCurrencyCode, [trade?.buyCurrencyCode]); // Foreign currency

    const thirdPartyBankAcctList = useMemo(() => thirdPartyBankAccounts?.filter((bankAcct) => bankAcct?.currencyCode === thirdPartyCurrencyCode && bankAcct?.isActive), [thirdPartyBankAccounts, thirdPartyCurrencyCode]);

    const thirdPartyBankAcct = thirdPartyBankAcctList[0]
    //Canada Provice Check
    // if (task.obligation.country === "Canada") {
    //     const province = CANADA_PROVINCE_CODE_TO_NAME[task.obligation.geographicalRegionCode]
    //     const thirdPartyAccount = CANADA_PROVINCE_TO_THIRDPARTY[province]
    //     if (thirdPartyAccount) {
    //         const acctList = thirdPartyBankAccounts?.filter((bankAcct) => bankAcct?.nickname === thirdPartyAccount);
    //         thirdPartyBankAcct = acctList[0];
    //     } else {
    //         thirdPartyBankAcct = thirdPartyBankAcctList[0]
    //     }
    // } else {
    //     thirdPartyBankAcct = thirdPartyBankAcctList[0];
    // }

    const balance = useMemo(() => balanceList?.find((bal) => bal?.currencyCode === currencyCode) ?? 0, [balanceList, currencyCode]);

    // const defaultPaymentDate = moment(trade.valueDate).add(2, 'day').endOf('day').format("YYYY-MM-DD");
    const defaultPaymentDate = moment(trade.valueDate).add(0, 'day').endOf('day').format("YYYY-MM-DD");
    const isTradeExpired = Date.parse(today) > Date.parse(trade?.valueDate);
    const hasPayment = Boolean(paymentId);
    const isMissingTrade = !Boolean(tradeId);
    const isPaymentDisabled = isMissingTrade || hasPayment || isTradeExpired;
    const paymentTooltipText = useMemo(() => {
        if (hasPayment) {
            return 'There is already an existing payment for this task.';
        } else if (isMissingTrade) {
            return 'Create a trade request first.';
        } else if (isTradeExpired) {
            return 'Trade has expired. Create another trade request to proceed with payment.';
        }
        return 'Create payment';
    }, [hasPayment, isMissingTrade, isTradeExpired]);

    // const defaultValues = useMemo(() => ({
    //     purpose: task?.paymentPurpose,
    //     amount: trade.sellAmount,
    //     reference: trade.clientReference,
    //     bankAccountId: thirdPartyBankAcct?.id,
    //     valueDate: defaultPaymentDate,
    // }), [defaultPaymentDate, task?.paymentPurpose, thirdPartyBankAcct?.id, trade.clientReference, trade.sellAmount]);

    //July25 amount in payments out should be buy amount not sell amount

    const defaultValues = useMemo(() => ({
        purpose: task?.paymentPurpose,
        amount: trade.buyAmount,
        reference: trade.clientReference,
        bankAccountId: thirdPartyBankAcct?.id,
        valueDate: defaultPaymentDate,
    }), [defaultPaymentDate, task?.paymentPurpose, thirdPartyBankAcct?.id, trade.clientReference, trade.buyAmount]);

    return {
        defaultValues,
        currencyCode,
        thirdPartyBankAcct,
        thirdPartyBankAcctList,
        thirdPartyCurrencyCode,
        trade,
        balance,
        isPaymentDisabled,
        paymentTooltipText,
        today,
        maxPaymentDate: defaultPaymentDate,
    };
};

export default usePaymentModal;